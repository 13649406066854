<template>
  <div class="van-cell calendars">
    <!-- input-align="right" -->
    <van-field
      readonly
      clickable
      :is-link="!readonly"
      :value="datas"
      :name="name"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      :disabled="disabled"
      @click="handField"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        ref="picker"
        show-toolbar
        :columns="columns"
        value-key="label"
        :defaultIndex="defaultIndex"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: [Array, Object],
      default: null
    },
    value: null,
    options: {
      type: [Array, Object],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'cascader'
    },
    separator: {
      type: String,
      default: '/'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      datas: '',
      defaultIndex: 0,
      columns: [],
      showPicker: false,
      optionCascader: []
    };
  },
  watch: {
    options: {
      handler: function() {
        this.into();
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    into() {
      this.handDatas(this.options);
      this.handColu(this.options);
    },
    handField() {
      if (!this.disabled && !this.readonly) {
        this.showPicker = true;
      }
    },
    handColu(data) {
      if (this.type === 'cascader') {
        let listData = this.cascaderColu(data, this.value);
        this.columns = listData;
      } else {
        this.columns = data;
      }
    },
    //级联默认选中项
    cascaderColu(data, val) {
      if (data) {
        let listColu = [];
        data.forEach((Item, index) => {
          let listChild;
          let childIndex = -1;
          if (Item.children && Item.children.length > 0) {
            listChild = this.cascaderColu(Item.children, val);
            if (val !== undefined && val) {
              Item.children.forEach((List, i) => {
                if (val.includes(List.value)) {
                  childIndex = i;
                }
              });
            }
            if (childIndex >= 0) {
              listColu.push({
                label: Item.label,
                value: Item.value,
                defaultIndex: childIndex,
                children: listChild
              });
            } else {
              listColu.push({
                label: Item.label,
                value: Item.value,
                children: listChild
              });
            }
          } else {
            listColu.push({ label: Item.label, value: Item.value });
          }
        });
        return listColu;
      }
    },

    //解析选中值的text
    handDatas(dataList) {
      let values = null;
      if (this.value) {
        if (this.type === 'cascader') {
          this.optionCascader = [];
          this.cascaderText(dataList, this.value);
          this.cascaderIndex(dataList, this.value);
          values = this.optionCascader.join(this.separator);
        } else {
          if (this.value != undefined) {
            dataList.forEach((data, index) => {
              if (data.value === this.value) {
                values = data.label;
                this.defaultIndex = index;
              }
            });
          }
        }
      }
      this.datas = values;
    },
    //解析级联显示值
    cascaderText(data, value) {
      if (data) {
        data.forEach((Item) => {
          if (value.includes(Item.value)) {
            this.optionCascader.push(Item.label);
          }
          if (Item.children && Item.children.length > 0) {
            this.cascaderText(Item.children, value);
          }
        });
      }
    },
    //解析第一列的index
    cascaderIndex(data, value) {
      if (value && value.length > 0) {
        data.forEach((Item, index) => {
          if (Item.value === value[0]) {
            this.defaultIndex = index;
          }
        });
      }
    },

    onConfirm(value, index) {
      if (this.type === 'cascader') {
        this.datas = value.join(this.separator);
        let Val = this.$refs.picker.getValues().map((Item) => {
          return Item.value;
        });
        this.$emit('input', Val);
      } else {
        this.datas = value.label;
        this.$emit('input', value.value);
      }
      this.showPicker = false;
    }
  }
};
</script>

<style lang="less" scoped>
.calendars {
  padding: 0;
}
</style>
