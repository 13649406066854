<template>
  <van-field :name="name" :label="label" :required="requiredRate" :rules="rules">
    <template #input>
      <van-rate
        color="#ffd21e"
        disabled-color="#ffd21e"
        v-model="data"
        :allow-half="allowHalf"
        :count="max"
        :disabled="disabledRate"
        @change="onChange"
      />
    </template>
  </van-field>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Array, Object],
      default: null,
    },
    value: {
      type: Number,
      default: 50,
    },
    allowHalf: {
      type: Boolean,
      default: true,
    },
    max: {
      type: Number,
      default: 5,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: this.value,
      disabledRate: this.disabled,
      requiredRate: this.required,
    };
  },
  watch:{
    readonly: {
      handler:function(){
        if(this.readonly){
          this.disabledRate = true;
          this.requiredRate = false;
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    onChange(value) {
      this.$emit("input",value);
    },
  },
};
</script>

<style>
</style>