<template>
  <div class="van-cell calendars">
    <van-field
      v-model="area"
      readonly
      clickable
      :label="areaLabel"
      :labelWidth="mapLabelWidth"
      :placeholder="areaPlaceholder"
      :rules="rules"
      @change="setAddress"
      :input-align="readonly ? 'right' : 'left'"
      @click="showPickerTree"
    >
      <!-- <template #right-icon>
        <div class="tipa-right" v-if="!readonly" @click="handerFixed">
          <van-icon name="aim" color="#4466ad" size="20" />
          <span class="tipa-fixed">定位</span>
        </div>
      </template> -->
    </van-field>
    <van-field
      v-model="detailedAddress"
      center
      clearable
      :label="fullAddressLabel"
      :labelWidth="mapLabelWidth"
      :placeholder="fullPlaceholder"
      :rules="rules"
      @input="setAddress"
      :input-align="readonly ? 'right' : 'left'"
      :readonly="readonly"
    />
    <van-popup class="popup-map" v-model="popupState" :style="{ height: '100%' }">
      <van-icon class="popup-icon" name="arrow-left" size="20" @click="popupClose" />
      <map-position @mapAddress="mapAddressInfor" />
    </van-popup>

    <van-popup
      v-model="showPicker"
      closeable
      close-icon-position="top-left"
      round
      position="bottom"
      :style="{ height: '70%' }"
      @close="regionClose"
    >
      <div class="election-site">
        <div class="election-site-title">
          <span>{{ areaLabel }}</span>
          <span>选择</span>
        </div>
        <div class="election-site-name">
          <div class="esn-region" v-for="item in electionTitle" :key="item.regionId">
            <span>{{ item.regionName }}</span>
          </div>
          <span v-if="electionPrompt">请选择</span>
        </div>
        <div class="election-site-content">
          <div class="esc-bar">
            <div v-if="treeItem">
              <van-cell
                v-for="item in treeItem"
                :key="item.regionId"
                :title="item.regionName"
                @click="electionAnchor(item, treeItem)"
              />
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import MapPosition from '@/components/vc-map-position/MapPosition';
import { selectRegionTree } from '@/api/components';
import serverconfig from '@/api/server.config';
const PARENT_REGION_ID = serverconfig.PARENT_REGION_ID;

export default {
  components: {
    MapPosition
  },
  props: {
    areaLabel: {
      type: String,
      default: null
    },
    fullAddressLabel: {
      type: String,
      default: null
    },
    areaPlaceholder: {
      type: String,
      default: null
    },
    fullPlaceholder: {
      type: String,
      default: null
    },
    areaValue: {
      type: String,
      default: null
    },
    fullAddressValue: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    mapLabelWidth: {
      type: Number,
      default: null
    },
    rules: {
      type: [Array, Object],
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler: function() {
        if (this.value) {
          let data = JSON.parse(this.value);
          this.area = data[this.areaValue];
          this.detailedAddress = data[this.fullAddressValue];
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popupState: false,
      electionPrompt: true,
      area: '',
      detailedAddress: '',
      regionId: '',
      showPicker: false,
      electionTitle: [],
      treeData: null,
      treeItem: null
    };
  },
  methods: {
    showPickerTree() {
      if (!this.readonly) {
        this.getRegionTree();
        this.electionPrompt = true;
        this.showPicker = true;
      }
    },
    /* 获取行政区域 */
    async getRegionTree() {
      const { status, resultData } = await selectRegionTree(PARENT_REGION_ID);
      if (status === 'complete') {
        this.treeData = resultData.nethallRegions;
        this.treeItem = resultData.nethallRegions;
      } else {
        this.treeData = null;
      }
    },
    handerFixed() {
      this.popupState = true;
    },
    electionAnchor(data, item) {
      this.electionTitle.push(data);
      if (data.regionChilds && data.regionChilds.length > 0) {
        this.treeItem = data.regionChilds;
      } else {
        this.electionPrompt = false;
        let obj = this.electionTitle;
        let name = [];
        obj.forEach((ele) => {
          name.push(ele.regionName);
        });
        this.area = name.join('/');
        this.regionId = data.regionId;
        this.showPicker = false;
        this.electionTitle = [];
        this.treeItem = null;

        let addressInfor = {};
        this.$set(addressInfor, this.areaValue, this.area);
        this.$set(addressInfor, this.areaValue, this.area);
        this.$set(addressInfor, 'regionId', this.regionId);
        this.$emit('input', JSON.stringify(addressInfor));
      }
    },
    regionClose() {
      this.electionPrompt = false;
      this.showPicker = false;
      this.electionTitle = [];
      this.treeItem = null;
    },
    mapAddressInfor(data) {
      this.popupState = false;
      this.area = data.area;
      this.detailedAddress = data.detailedAddress;
      let addressInfor = {};
      this.$set(addressInfor, this.areaValue, this.area);
      this.$set(addressInfor, this.fullAddressValue, this.detailedAddress);
      this.$set(addressInfor, 'regionId', this.regionId);
      this.$emit('input', JSON.stringify(addressInfor));
    },
    popupClose() {
      this.popupState = false;
    },
    setAddress() {
      let addressInfor = {};
      this.$set(addressInfor, this.areaValue, this.area);
      this.$set(addressInfor, this.fullAddressValue, this.detailedAddress);
      this.$set(addressInfor, 'regionId', this.regionId);
      this.$emit('input', JSON.stringify(addressInfor));
    }
  },
  computed: {
    scrollerHeight: function() {
      return window.innerHeight + 'px';
    }
  }
};
</script>

<style lang="less" scoped>
.calendars {
  padding: 0;
  flex-direction: column;
}
.tipa-right {
  display: flex;
  align-items: center;
}
.tipa-fixed {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #617dbc;
  padding-left: 6px;
}
.popup-map {
  width: 100%;
  top: 0;
  left: 0;
  transform: none;
  .popup-icon {
    position: fixed;
    z-index: 3333;
    top: 0;
    left: 0;
    padding: 14px 14px;
  }
}
.election-site {
  display: flex;
  flex-direction: column;
  height: 100%;

  .election-site-title {
    font-size: 20px;
    text-align: center;
    line-height: 54px;
    height: 54px;
    border-bottom: 1px solid #ebedf0;
    box-sizing: border-box;
  }

  .election-site-name {
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    display: flex;
    border-bottom: 1px solid #ebedf0;
    .esn-region {
      margin-right: 8px;
    }
  }

  .election-site-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    .esc-bar {
      width: 100%;
      overflow: scroll;
    }
  }
}
</style>
