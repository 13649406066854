<template>
  <div class="van-cell calendars">
    <van-field :name="name" :label="label" :required="requiredRate" :rules="rules">
      <template #input>
        <van-slider
          v-model="data"
          :min="min"
          :max="max"
          :step="step"
          :disabled="disabledRate"
          bar-height="6px"
          active-color="#1989fa"
          @change="onChange"
        />
      </template>
    </van-field>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Array, Object],
      default: null,
    },
    value: {
      type: Number,
      default: 50,
    },
    min: {
      typy: Number,
      default: 0,
    },
    max: {
      typy: Number,
      default: 100,
    },
    step: {
      typy: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: this.value,
      disabledRate: this.disabled,
      requiredRate: this.required,
    };
  },
  watch:{
    readonly: {
      handler:function(){
        if(this.readonly){
          this.disabledRate = true;
          this.requiredRate = false;
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="less" scoped>
.calendars {
  padding: 0;
}
</style>