import { deepClone } from '@/utils/index';
import request from '@/utils/service';
import VcCalendar from '@/components/vc-calendar';
import VcDatetime from '@/components/vc-datetime';
import VcSlider from '@/components/vc-slider';
import VcRate from '@/components/vc-rate';
import VcStepper from '@/components/vc-stepper';
import VcUploader from '@/components/vc-uploader';
import VcPicker from '@/components/vc-picker';
import VcSwitch from '@/components/vc-switch';
import VcMapPosition from '@/components/vc-map-position';
import VcInputButton from '@/components/vc-input-button';
import VcCascader from '@/components/vc-cascader';
import VcTimes from '@/components/vc-times';
import VcTwoCascade from '@/components/vc-two-cascade';
import AnnexRun from '@/components/annex-run';
import dateFormat from './date-format';
import pickerText from './picker-text';

const componentChild = {};
const attrChild = {};
const datePickerElems = ['van-field', 'vc-calendar', 'vc-picker'];

/**
 * 将./slots中的文件挂载到对象componentChild上
 * 文件名为key，对应JSON配置中的__config__.tag
 * 文件内容为value，解析JSON配置中的__slot__
 */
const slotsFiles = require.context('./slots', false, /\.js$/);
const keys = slotsFiles.keys() || [];
keys.forEach((key) => {
  const tag = key.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = slotsFiles(key).default;
  componentChild[tag] = value;
});

const attrsFiles = require.context('./attrs', false, /\.js$/);
const attrKeys = attrsFiles.keys() || [];
attrKeys.forEach((key) => {
  const tag = key.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = attrsFiles(key).default;
  attrChild[tag] = value;
});

const description = {
  'van-field'(h, conf) {
    const { type, maxlength, autosize, __config__, __slot__ } = conf;
    const { defaultValue, label } = __config__;
    const slots = __slot__ || {};
    const alignType = type === 'textarea';
    if (alignType) {
      const Rows = autosize.maxRows;
      return makeText(h, label, defaultValue, slots.suffix, Rows, maxlength);
    } else {
      return makeValue(h, label, defaultValue, slots.suffix);
    }
  },
  'vc-picker'(h, conf) {
    const config = conf.__config__;
    const { label } = config;
    const slots = conf.__slot__ || {};
    let value = pickerText(conf, slots);
    return makeValue(h, label, value, slots.suffix);
  },
  'vc-calendar'(h, conf) {
    const config = conf.__config__;
    const { defaultValue, label } = config;
    let valueFormat = conf.format;
    let separator = conf['range-separator'];
    let value = dateFormat(defaultValue, valueFormat, separator);
    return makeValue(h, label, value);
  },
  'vc-datetime'(h, conf) {
    const config = conf.__config__;
    const { defaultValue, label } = config;
    return makeValue(h, label, defaultValue);
  },
  'van-radio-group'(h, conf) {
    const config = conf.__config__;
    const { defaultValue, label } = config;
    const slots = conf.__slot__ || {};
    let options = slots.options || [];
    let item = options.find((item) => item.value === defaultValue);
    let value = item ? item.label : '';
    return makeValue(h, label, value);
  },
  'vc-stepper'(h, conf) {
    const config = conf.__config__;
    const { defaultValue, label } = config;
    return makeValue(h, label, defaultValue);
  }
};

function makeValue(h, label, value, suffix) {
  let val = value ? (suffix ? value + suffix : value) : '';
  return <van-field label={label} value={val} input-align="right" readonly />;
}
function makeText(h, label, value, suffix, Rows, maxLength) {
  let val = value ? (suffix ? value + suffix : value) : '';
  return (
    <van-field
      label={label}
      value={val}
      rows={Rows}
      input-align="left"
      maxlength={maxLength}
      show-word-limit
      type="textarea"
      readonly
    />
  );
}

function vModel(dataObject, defaultValue) {
  let { tag } = this.conf.__config__;

  dataObject.props.value = defaultValue;

  dataObject.on.input = (event) => {
    // console.log('input=> ', event);
    let value = event;

    this.$emit('input', value);
  };

  dataObject.on.change = (event) => {
    // console.log('change=> ', tag, event);
    // 部分元素没有发送 input，手动触发

    this.$emit('change', event);
  };

  dataObject.on.blur = (event) => {
    this.$emit('blur', event);
  };
}

function mountSlotFlies(h, confClone, children) {
  const childObjs = componentChild[confClone.__config__.tag];
  if (childObjs) {
    Object.keys(childObjs).forEach((key) => {
      const childFunc = childObjs[key];
      if (confClone.__slot__ && confClone.__slot__[key]) {
        children.push(childFunc(h, confClone, key));
      }
    });
  }
}

function emitEvents(confClone) {
  ['on', 'nativeOn'].forEach((attr) => {
    const eventKeyList = Object.keys(confClone[attr] || {});
    eventKeyList.forEach((key) => {
      const val = confClone[attr][key];
      if (typeof val === 'string') {
        confClone[attr][key] = (event) => this.$emit(val, event);
      }
    });
  });
}

function termsReckon(data, props) {
  let item = [];
  if (data && data.length) {
    data.forEach((el) => {
      let obj = {};
      const child = el[props.children];
      if (child && child.length) {
        let childList = termsReckon(child, props);
        obj = { ...el, label: el[props.label], value: el[props.value], children: childList };
      } else {
        obj = { ...el, label: el[props.label], value: el[props.value] };
      }
      item.push(obj);
    });
  }
  return item;
}

function asyncDataMount() {
  let params = this.params || {};
  let { url, method } = this.conf.__config__;
  if (url && method) {
    request({
      method,
      url,
      headers: {
        ...params.headers
      }
    }).then((res) => {
      if (res.status === 'complete') {
        const data = res[this.conf.__config__.dataKey];
        if (data && data.length) {
          const options = termsReckon(data, this.conf.props.props);
          this.conf.__slot__.options = options;
        }
        this.$emit('changeOptions', res.resultData);
      }
    });
  }
}

function buildDescObject(confClone, dataObject) {
  dataObject.props.readonly = true;

  Object.keys(confClone).forEach((key) => {
    const val = confClone[key];
    if (key === '__vModel__') {
      dataObject.props.value = confClone.__config__.defaultValue;
    } else if (key === '__slot__') {
      dataObject.props.options = val.options || null;
    } else if (dataObject[key]) {
      dataObject[key] = { ...dataObject[key], ...val };
    } else {
      let buildAttrFunc = attrChild[confClone.__config__.tag];
      if (buildAttrFunc) {
        buildAttrFunc.call(this, dataObject.attrs, key, val);
      } else {
        dataObject.attrs[key] = val;
      }
    }
  });
  // 清理属性
  clearAttrs(dataObject);
}

function buildDataObject(confClone, dataObject) {
  Object.keys(confClone).forEach((key) => {
    const val = confClone[key];

    if (key === '__vModel__') {
      vModel.call(this, dataObject, confClone.__config__.defaultValue);
    } else if (key === '__slot__') {
      dataObject.props.options = val.options || null;
    } else if (dataObject[key]) {
      dataObject[key] = { ...dataObject[key], ...val };
    } else {
      let buildAttrFunc = attrChild[confClone.__config__.tag];
      if (buildAttrFunc) {
        buildAttrFunc.call(this, dataObject.attrs, key, val);
      } else {
        dataObject.attrs[key] = val;
      }
    }
  });

  // 清理属性
  clearAttrs(dataObject);
}

function clearAttrs(dataObject) {
  delete dataObject.attrs.__config__;
  delete dataObject.attrs.__slot__;
  delete dataObject.attrs.__methods__;
}

function makeDataObject() {
  return {
    attrs: {},
    props: {},
    nativeOn: {},
    on: {},
    style: {}
  };
}

function renderDesctiption(h) {
  const confClone = deepClone(this.conf);
  const config = confClone.__config__;
  const { tag } = config;
  const descriptionFunc = description[tag];

  if (descriptionFunc) {
    return descriptionFunc.call(this, h, confClone);
  } else {
    const dataObject = makeDataObject();

    // 将json表单配置转化为vue render可以识别的 “数据对象（dataObject）”
    buildDescObject.call(this, confClone, dataObject);

    return h(this.conf.__config__.tag, dataObject);
  }
}

function renderFormItem(h) {
  const dataObject = makeDataObject();
  const confClone = deepClone(this.conf);
  const children = this.$slots.default || [];

  // 如果slots文件夹存在与当前tag同名的文件，则执行文件中的代码
  mountSlotFlies.call(this, h, confClone, children);

  // 将字符串类型的事件，发送为消息
  emitEvents.call(this, confClone);

  // 将json表单配置转化为vue render可以识别的 “数据对象（dataObject）”
  buildDataObject.call(this, confClone, dataObject);
  // console.log('tag=', this.conf.__config__.tag, dataObject, children);
  return h(this.conf.__config__.tag, dataObject, children);
}

export default {
  props: {
    conf: {
      type: Object,
      required: true
    },
    params: {
      type: Object
    },
    description: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VcCalendar,
    VcDatetime,
    VcSlider,
    VcRate,
    VcStepper,
    VcUploader,
    VcPicker,
    VcSwitch,
    VcMapPosition,
    VcInputButton,
    VcCascader,
    VcTimes,
    VcTwoCascade,
    AnnexRun
  },
  mounted() {
    // 动态数据绑定
    asyncDataMount.call(this);
  },
  render(h) {
    if (this.description) {
      return renderDesctiption.call(this, h);
    }
    return renderFormItem.call(this, h);
  }
};
