<template>
  <div class="uploader-one van-cell calendars" v-if="uploadNumber === 1">
    <van-field :name="name" :rules="rulesRevise" :border="false">
      <template #input>
        <!-- :accept="accept" -->
        <van-uploader
          class="uploader-one-vant"
          v-model="uploader"
          :max-size="fileKB"
          :multiple="multiple"
          :disabled="disabledRate"
          :deletable="!disabledRate"
          @oversize="onOversize"
          :after-read="afterRead"
          :max-count="uploadNumber"
          :before-delete="uploadDelete"
        />
        <div class="uploader-one-label">
          <span class="uol-name"><span v-if="requireds">*</span>{{ label }}</span>
          <div class="uol-examples" v-if="examplesUrl" @click="handerExamples">
            查看示例
          </div>
        </div>
      </template>
    </van-field>
  </div>
  <div class="uploader-over van-cell calendars" v-else>
    <van-cell-group :border="false">
      <div class="uploader-label"><span v-if="requireds" class="required-icon">*</span>{{ label }}</div>
      <van-field :name="name" :rules="rulesRevise" :border="false">
        <!-- :accept="accept" -->
        <template #input>
          <van-uploader
            v-model="uploader"
            :max-size="fileKB"
            :multiple="multiple"
            :disabled="disabledRate"
            :deletable="!disabledRate"
            @oversize="onOversize"
            :after-read="afterRead"
            :max-count="uploadNumber"
            :before-delete="uploadDelete"
          />
        </template>
      </van-field>
    </van-cell-group>
  </div>
</template>

<script>
import { Toast, ImagePreview } from 'vant';
import { uploadPicture, getPictureUrl, getPictureData } from '@/api/components';

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: String,
      default: null
    },
    rules: {
      type: [Array, Object],
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    fileSize: {
      type: Number,
      default: 2
    },
    sizeUnit: {
      type: String,
      default: 'MB'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // action: {
    //   type: String,
    //   default: ''
    // },
    readonly: {
      type: Boolean,
      default: false
    },
    uploadNumber: {
      type: Number,
      default: 1
    },
    examplesUrl: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      pictureType: ['jpeg', 'jpg', 'png', 'jfif', 'bmp', 'tiff', 'gif', 'mp4'],
      requireds: false,
      uploader: [],
      uploaderID: [],
      fileKB: this.handFileKB(),
      disabledRate: false,
      rulesRevise: null,
      stopWarch: true
    };
  },
  watch: {
    readonly: {
      handler: function() {
        if (this.readonly) {
          this.disabledRate = true;
        }
      },
      deep: true,
      immediate: true
    },
    rules: {
      handler: function() {
        if (this.rules && this.rules.length > 0 && this.rules[0].required) {
          this.rulesRevise = [{ required: true, message: this.placeholder }];
          this.requireds = true;
        }
      },
      deep: true,
      immediate: true
    },
    value: {
      handler: function() {
        if (this.value && this.stopWarch) {
          this.handerFileUrl(this.value);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //计算附件大小
    handFileKB() {
      if (this.sizeUnit === 'KB') {
        return this.fileSize * 1024;
      }
      if (this.sizeUnit === 'MB') {
        return this.fileSize * 1024 * 1024;
      }
      if (this.sizeUnit === 'GB') {
        return this.fileSize * 1024 * 1024 * 1024;
      }
    },
    // 文件超过大小提示
    onOversize(file) {
      Toast('文件大小不能超过 ' + this.fileSize + this.sizeUnit);
    },
    // 文件上传
    async afterRead(file, detail) {
      this.stopWarch = false;
      let fileStream = file.content;
      let fileName = file.file.name;
      file.status = 'uploading';
      file.message = '上传中...';
      let suffix = fileName.split('.').pop();
      let data = {
        endWith: suffix,
        fileType: suffix,
        imageString: fileStream,
        pictureRealName: fileName,
        returnFlag: false
      };
      uploadPicture(data)
        .then((res) => {
          let { status, resultData } = res;
          if (status === 'complete') {
            file.status = 'success';
            file.message = '上传成功';
            file.id = resultData;
            this.uploaderID.push(resultData);
          } else {
            file.status = 'failed';
            file.message = '上传失败';
          }
          this.$emit('input', JSON.stringify(this.uploaderID));
        })
        .catch((e) => {
          file.status = 'failed';
          file.message = '上传失败';
        });
    },
    /* 解析图片url */
    async handerFileUrl(data) {
      this.uploaderID = JSON.parse(data);
      this.uploader = [];
      if (this.uploaderID && this.uploaderID.length > 0) {
        await getPictureData(this.uploaderID).then((res) => {
          const { status, resultData } = res;
          if (status === 'complete') {
            resultData.forEach((item) => {
              if (item.fileType !== undefined && this.pictureType.indexOf(item.fileType) < 0) {
                let obj = { id: item.pictureId, url: item.pictureRealName };
                this.uploader.push(obj);
              } else {
                // let obj = { id: item.pictureId, url: item.pictureUrl };
                let obj = {
                  id: item.pictureId,
                  url: 'data:image/' + item.fileType + ';base64,' + item.pictureUrl,
                  isImage: true
                };
                this.uploader.push(obj);
              }
            });
          }
        });
      }
    },
    /* 附件删除 */
    uploadDelete(data) {
      let id = data.id;
      let index = this.uploaderID.indexOf(data.id);
      this.uploaderID.splice(index, 1);
      this.$emit('input', JSON.stringify(this.uploaderID));
      return true;
    },
    //查看示例
    handerExamples() {
      if (this.examplesUrl) {
        let data = this.examplesUrl.split(',');
        ImagePreview({
          images: data,
          closeable: true
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.uploader-one {
  width: calc(100% / 3 - 10px);
  min-width: 88px;
  float: left;
  height: 185px;
  /deep/.van-cell {
    padding: 16px 0 0 0;
  }
  /deep/.van-field__control {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  /deep/.van-uploader__upload {
    margin: 0;
  }
  /deep/.van-overlay {
    background-color: rgba(0, 0, 0, 0.9);
  }
  /deep/.van-field__error-message {
    text-align: center;
  }
  /deep/.van-uploader__preview {
    margin: 0;
  }
  /deep/.van-field__error-message {
    line-height: 18px;
  }
  .uploader-one-label {
    margin-top: 6px;
    text-align: center;
    // height: 50px;
    .uol-name {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65);
      span {
        color: #fb5906;
      }
    }
    .uol-examples {
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: #4a90e2;
    }
  }
}
.required-icon {
  color: #fb5906;
}
.uploader-over {
  float: left;
  width: 100%;
}
.uploader-label {
  background: #ffffff;
  padding: 10px 16px 0px 16px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.calendars {
  padding: 0;
  flex-direction: column;
}
</style>
