export default {
  options(h, conf, key) {
    const list = [];
    conf.__slot__.options.forEach((item) => {
      list.push(
        <van-checkbox name={item.value} shape="square">
          {item.label}
        </van-checkbox>
      );
    });
    return list;
  },
};
