<template>
  <div>
    <vc-picker
      label="产权有效证明"
      placeholder="请选择产权证明类型"
      :value="fileList.prove"
      :options="options"
      :rules="[{ required: true }]"
      @input="changeValue"
      :disabled="disabledRate"
    />
    <p v-if="fileList.prove === 1" style="font-size:12px; text-align:left;color: #FC882C;margin: 5px 16px 0px">
      请确保产权证明地址与用户地址一致,且户主为当前认证人。
    </p>
    <div v-if="fileList.prove === 1" key="1">
      <vc-uploader
        label="房地产地址页"
        :readonly="readonly"
        :uploadNumber="1"
        :rules="[{ required: true, message: '请上传' }]"
        placeholder="请上传"
        :accept="accept"
        :fileSize="fileSize"
        sizeUnit="MB"
        :value="fileList.propertyAddress"
        @input="(data) => (fileList.propertyAddress = data)"
        examplesUrl="http://139.196.86.193:9000/wpg.net.hall/2020/12/25/1814167772800614400163ebcc07b.png"
      />
      <!-- examplesUrl="http://139.196.86.193:9000/wpg.net.hall/2020/11/13/4066382cc530000163e94c08a.jpg" -->
      <vc-uploader
        label="其它页"
        :readonly="readonly"
        :uploadNumber="1"
        :accept="accept"
        :fileSize="fileSize"
        sizeUnit="MB"
        :value="fileList.otherPagesa"
        @input="(data) => (fileList.otherPagesa = data)"
      />
      <vc-uploader
        label="其它页"
        :readonly="readonly"
        :uploadNumber="1"
        :accept="accept"
        :fileSize="fileSize"
        sizeUnit="MB"
        :value="fileList.otherPagesb"
        @input="(data) => (fileList.otherPagesb = data)"
      />
      <vc-uploader
        label="其他页"
        :readonly="readonly"
        :uploadNumber="1"
        :accept="accept"
        :fileSize="fileSize"
        sizeUnit="MB"
        :value="fileList.otherPagesc"
        @input="(data) => (fileList.otherPagesc = data)"
      />
      <vc-uploader
        label="其他页"
        :readonly="readonly"
        :uploadNumber="1"
        :accept="accept"
        :fileSize="fileSize"
        sizeUnit="MB"
        :value="fileList.otherPagesd"
        @input="(data) => (fileList.otherPagesd = data)"
      />
      <vc-uploader
        label="其他页"
        :readonly="readonly"
        :uploadNumber="1"
        :accept="accept"
        :fileSize="fileSize"
        sizeUnit="MB"
        :value="fileList.otherPagese"
        @input="(data) => (fileList.otherPagese = data)"
      />
    </div>
    <div v-if="fileList.prove === 2" key="2">
      <vc-uploader
        label="房产地址页"
        :readonly="readonly"
        :uploadNumber="1"
        placeholder="请上传"
        :accept="accept"
        :fileSize="fileSize"
        sizeUnit="MB"
        :value="fileList.realEstateAddress"
        @input="(data) => (fileList.realEstateAddress = data)"
      />
      <vc-uploader
        label="合同签字页"
        :readonly="readonly"
        :uploadNumber="1"
        placeholder="请上传"
        :accept="accept"
        :fileSize="fileSize"
        sizeUnit="MB"
        :value="fileList.contractSignature"
        @input="(data) => (fileList.contractSignature = data)"
      />
      <vc-uploader
        label="购房双方盖章"
        :readonly="readonly"
        :uploadNumber="1"
        placeholder="请上传"
        :accept="accept"
        :fileSize="fileSize"
        sizeUnit="MB"
        :value="fileList.bothParties"
        @input="(data) => (fileList.bothParties = data)"
      />
      <vc-uploader
        label="其它页"
        :readonly="readonly"
        :uploadNumber="1"
        placeholder="请上传"
        :accept="accept"
        :fileSize="fileSize"
        sizeUnit="MB"
        :value="fileList.housePagesa"
        @input="(data) => (fileList.housePagesa = data)"
      />
      <vc-uploader
        label="其它页"
        :readonly="readonly"
        :uploadNumber="1"
        placeholder="请上传"
        :accept="accept"
        :fileSize="fileSize"
        sizeUnit="MB"
        :value="fileList.housePagesb"
        @input="(data) => (fileList.housePagesb = data)"
      />
    </div>
    <div v-if="fileList.prove === 3" key="3">
      <vc-uploader
        label=""
        :readonly="readonly"
        :uploadNumber="6"
        :rules="[{ required: false, message: '请上传' }]"
        placeholder="请上传"
        :accept="accept"
        :fileSize="fileSize"
        sizeUnit="MB"
        :value="fileList.caseRegistration"
        @input="(data) => (fileList.caseRegistration = data)"
      />
    </div>
    <div v-if="fileList.prove === 4" key="4">
      <vc-uploader
        label=""
        :readonly="readonly"
        :uploadNumber="6"
        placeholder="请上传"
        :accept="accept"
        :fileSize="fileSize"
        sizeUnit="MB"
        :value="fileList.demolition"
        @input="(data) => (fileList.demolition = data)"
      />
    </div>
  </div>
</template>

<script>
import VcPicker from '@/components/vc-picker';
import VcUploader from '@/components/vc-uploader';
const defaultFileList = {
  propertyAddress: null,
  otherPagesa: null,
  otherPagesb: null,
  otherPagesc: null,
  otherPagesd: null,
  otherPagese: null,
  realEstateAddress: null,
  contractSignature: null,
  bothParties: null,
  housePagesa: null,
  housePagesb: null,
  caseRegistration: null,
  demolition: null
};
export default {
  components: {
    VcPicker,
    VcUploader
  },
  data() {
    return {
      fileSize: 5,
      accept: 'image/*',
      options: [
        {
          label: '房产证',
          value: 1
        },
        {
          label: '购房合同',
          value: 2
        },
        {
          label: '房地产交易中心备案登记证明',
          value: 3
        },
        {
          label: '拆迁安置协议和排房单',
          value: 4
        }
      ],
      fileList: {
        prove: 1,
        propertyAddress: null,
        otherPagesa: null,
        otherPagesb: null,
        otherPagesc: null,
        otherPagesd: null,
        otherPagese: null,
        realEstateAddress: null,
        contractSignature: null,
        bothParties: null,
        housePagesa: null,
        housePagesb: null,
        caseRegistration: null,
        demolition: null
      },
      disabledRate: false
    };
  },
  props: {
    value: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  mounted() {},
  methods: {
    changeValue(value) {
      this.fileList.prove = value;
      this.fileList = { ...defaultFileList, prove: value };
    }
  },
  watch: {
    fileList: {
      handler: function() {
        let data = JSON.stringify(this.fileList);
        this.$emit('input', data);
      },
      deep: true,
      immediate: true
    },
    value: {
      handler: function() {
        let data = JSON.parse(this.value);
        this.fileList = { ...this.fileList, ...data };
      },
      deep: true,
      immediate: true
    },
    readonly: {
      handler: function() {
        if (this.readonly) {
          this.disabledRate = true;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style></style>
