<template>
  <div class="van-cell calendars">
    <van-field :name="name" :label="label" :required="requiredRate">
      <template #input>
        <van-switch
          v-model="data"
          size="20"
          :active-color="activeColor"
          :inactive-color="inactiveColor"
          :disabled="disabledRate"
          @change="onChange"
        />
      </template>
    </van-field>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: false,
    disabled: {
      type: Boolean,
      default: false,
    },
    activeValue: true,
    inactiveValue: false,
    activeColor: {
      type: String,
      default: "#1989fa",
    },
    inactiveColor: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: false,
      disabledRate: this.disabled,
      requiredRate: this.required,
    };
  },
  watch: {
    value: {
      handler: function () {
        if (this.value === this.activeValue) {
          this.data = true;
        } else {
          this.data = false;
        }
      },
      deep: true,
      immediate: true,
    },
    readonly: {
      handler:function(){
        if(this.readonly){
          this.disabledRate = true;
          this.requiredRate = false;
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    onChange(value) {
      if (value) {
        this.$emit("input", this.activeValue);
      } else {
        this.$emit("input", this.inactiveValue);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.calendars {
  padding: 0;
}
</style>