<template>
  <van-field
    v-model="sms"
    center
    clearable
    :label="label"
    :labelWidth="labelWidth"
    :placeholder="placeholder"
    :rules="rules"
    :input-align="readonly ? 'right' : 'left'"
    :readonly="readonly"
    @input="handerInput"
  >
    <template #button>
      <div :class="show ? 'input-button' : 'input-button-hide'" v-if="!readonly" @click="handerSms">
        <span v-if="!show">{{ count + 's后重新获取' }}</span>
        <span v-if="show">{{ buttonText }}</span>
      </div>
    </template>
  </van-field>
</template>

<script>
import { Toast } from 'vant';
import { getCode } from '@/api/home';
import { isMobile } from '@/utils/validator';

export default {
  props: {
    buttonText: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    rules: {
      type: [Array, Object],
      default: null
    },
    labelWidth: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    actionButton: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler: function() {
        if (this.value) {
          this.sms = this.value;
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      count: 60,
      show: true,
      sms: ''
    };
  },
  methods: {
    handerInput() {
      this.$emit('input', this.sms);
    },
    async handerSms() {
      if (this.show) {
        if (isMobile(this.sms)) {
          this.getCode();
          let arg = {
            phone: this.sms
          }
          const {status, resultData} = await getCode(arg);
          if (status === 'complete') {
            Toast({ icon: 'passed', message: '发送成功！' });
          } else {
            Toast({ icon: 'close', message: '发送失败！' });
          }
        } else {
          Toast('请填写正确的手机号')
        }
      }
    },
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.input-button {
  padding: 4px 10px;
  border-radius: 8px;
  background: transparent;
  border: 1px solid rgba(66, 134, 245);
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: rgba(66, 134, 245);
}
.input-button-hide {
  padding: 4px 10px;
  border-radius: 8px;
  background: #f8f8f8;
  border: 1px solid rgba(66, 134, 245, 0.6);
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: rgba(66, 134, 245);
}
</style>
