<template>
  <div class="map-fixed">
    <div class="head-search">
      <van-field
        id="suggestId"
        v-model="searchAddress"
        left-icon="search"
        placeholder="小区/写字楼/学校等"
        label-width="30px"
        clearable
        @focus="handerPopup"
      />
      <div class="cancel-button" v-if="popupState" @click="handerCancel">取消</div>
      <van-popup v-model="popupState" :get-container="getContainer" :style="{ height: '100%' }">
        <div class="cancel-search-popup">
          <div v-if="getLongitude">
            <van-cell-group
              v-for="(item, index) in searchList"
              :key="index"
              @click="handerPlace(item)"
            >
              <van-cell center>
                <template #title>
                  <span class="current-span">{{ item.business }} </span>
                </template>
                <template #label>
                  <span>{{ item.city + item.district }}</span>
                </template>
                <template #right-icon>
                  <van-icon name="aim" size="1.2em" />
                </template>
              </van-cell>
            </van-cell-group>
          </div>
          <div class="bear-invalid" v-else>
            <van-image
              class="bear-invalid-img"
              width="8em"
              height="8em"
              fit="cover"
              :src="imgInvalid"
            />
            <div>未找到相关结果</div>
            <div class="bear-invalid-label">
              建议您先只输入小区、办公楼、路名等，稍后在补充详细地址
            </div>
          </div>
        </div>
      </van-popup>
    </div>
    <div class="my-container">
      <baidu-map class="allmap" :scroll-wheel-zoom="true" @ready="mapReady" :ak="MapAk">
        <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT" />
        <bm-geolocation
          anchor="BMAP_ANCHOR_TOP_LEFT"
          :showAddressBar="true"
          :autoLocation="true"
          @locationSuccess="getlocationSuccess"
          @locationError="getLocationError"
        />
      </baidu-map>
      <div class="bear-container" v-if="getLongitude">
        <div class="bear-list">
          <van-cell-group>
            <van-cell center v-if="currentAddress" @click="handerCurrentAddress(currentAddress)">
              <template #right-icon>
                <van-icon name="aim" size="1.2em" color="#4286f5" />
              </template>
              <template #title>
                <span class="current-span">当前定位</span>
              </template>
              <template #label>
                <span>{{ currentAddress.address }}</span>
              </template>
            </van-cell>
          </van-cell-group>
          <van-cell-group v-if="currentAddress">
            <van-cell
              center
              v-for="(item, index) in currentAddress.surroundingPois"
              :key="index"
              @click="handerAddress(item)"
            >
              <template #right-icon>
                <van-icon name="aim" size="1.2em" />
              </template>
              <template #title>
                <span>{{ item.title }}</span>
              </template>
              <template #label>
                <span>{{ item.address }}</span>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </div>
      <div class="bear-invalid" v-else>
        <van-image
          class="bear-invalid-img"
          width="8em"
          height="8em"
          fit="cover"
          :src="imgInvalid"
        />
        <div>定位失败</div>
        <div class="bear-invalid-label">请查看是否打开微信的定位</div>
      </div>
    </div>
  </div>
</template>

<script>
import { BaiduMap, BmNavigation, BmGeolocation } from 'vue-baidu-map';
import serverconfig from '@/api/server.config';
const AK = serverconfig.AK;
const ImgInvalid = require('../../assets/icon/pic_无效.png');
export default {
  components: {
    BaiduMap,
    BmNavigation,
    BmGeolocation
  },
  data() {
    return {
      BMap: null,
      map: null,
      searchAddress: '',
      point: null,
      searchList: null,
      currentAddress: null,
      popupState: false,
      getLongitude: true,
      imgInvalid: ImgInvalid
    };
  },
  computed: {
    MapAk() {
      return AK;
    }
  },
  methods: {
    //地图实例渲染
    async mapReady({ BMap, map }) {
      this.map = map;
      this.BMap = BMap;
      let VM = this;
      //地图点击事件
      map.addEventListener('click', function(e) {
        map.clearOverlays();
        VM.point = new BMap.Point(e.point.lng, e.point.lat);
        let mk = new BMap.Marker(VM.point);
        map.addOverlay(mk);
        map.panTo(VM.point);
        VM.getInformation();
      });
      //获取当前位置
      var geolocation = new BMap.Geolocation();
      await geolocation.getCurrentPosition(function(r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          // 绘制中心点
          VM.point = new BMap.Point(r.longitude, r.latitude);
          map.centerAndZoom(VM.point, 15);
          let mk = new BMap.Marker(VM.point);
          map.addOverlay(mk);
          map.panTo(VM.point);
          VM.getInformation();
          VM.getLongitude = true;
        } else {
          VM.getLongitude = false;
        }
      });
      //百度地址搜索
      var ac = new BMap.Autocomplete({
        input: 'suggestId',
        location: map,
        onSearchComplete: function(e) {
          ac.hide();
          VM.searchList = e.Hr;
        }
      });
    },
    //地图定位成功
    getlocationSuccess(e) {
      this.getLongitude = true;
      this.map.clearOverlays();
      this.point = new this.BMap.Point(e.point.lng, e.point.lat);
      let mk = new this.BMap.Marker(e.point);
      this.map.centerAndZoom(this.point, 15);
      this.map.addOverlay(mk);
      this.map.panTo(this.point);
      this.getInformation();
    },
    //地图定位失败
    getLocationError() {
      this.getLongitude = false;
    },
    //指定的坐标点进行反地址解析
    getInformation() {
      let VM = this;
      var gc = new VM.BMap.Geocoder();
      gc.getLocation(
        VM.point,
        function(rs) {
          VM.currentAddress = rs;
        },
        {
          poiRadius: 1000,
          numPois: 30
        }
      );
    },
    //搜索结果点击事件
    handerPlace(data) {
      let VM = this;
      this.popupState = false;
      var myValue = data.business;
      var local = new VM.BMap.LocalSearch(VM.map, {
        //智能搜索
        onSearchComplete: function() {
          var pp = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
          VM.map.clearOverlays();
          VM.point = new BMap.Point(pp.lng, pp.lat);
          VM.map.centerAndZoom(VM.point, 15);
          let mk = new BMap.Marker(VM.point);
          VM.map.addOverlay(mk);
          VM.map.panTo(VM.point);
          VM.getInformation();
        }
      });
      local.search(myValue);
    },
    handerPopup() {
      this.searchList = null,
      this.popupState = true;
    },
    handerCancel() {
      this.popupState = false;
    },
    getContainer() {
      return document.querySelector('.my-container');
    },
    handerCurrentAddress(data) {
      let e = data.addressComponents;
      let mapAddres = {
        area: e.province + e.city + e.district,
        detailedAddress: e.street + e.streetNumber
      };
      this.$emit('mapAddress', mapAddres);
    },
    handerAddress(data) {
      let VM = this;
      var gc = new VM.BMap.Geocoder();
      gc.getLocation(data.point, function(rs) {
        let e = rs.addressComponents;
        let mapAddres = {
          area: e.province + e.city + e.district,
          detailedAddress: e.street + e.streetNumber
        };
        VM.$emit('mapAddress', mapAddres);
      });
    }
  }
};
</script>

<style lang="less" scoped>
.map-fixed {
  height: 100%;
  display: flex;
  flex-direction: column;
  .head-search {
    background: #ffffff;
    display: flex;
    justify-content: flex-end;
    height: 48px;
    z-index: 3000;
    .van-cell {
      background: #f6f6f6;
      border-radius: 30px;
      padding: 2px 10px;
      margin: 10px 16px 10px 40px;
    }
    .cancel-button {
      width: 3em;
      padding-right: 16px;
      text-align: center;
      line-height: 40px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .my-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .allmap {
      height: 280px;
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
    }
    .bear-container {
      flex: 1;
      overflow: hidden;
      .bear-list {
        height: 100%;
        overflow: auto;
      }
    }
  }
}
.bear-invalid {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #a2b0c6;
  .bear-invalid-img {
    margin: 4em 0 1em 0;
  }
  .bear-invalid-label {
    width: 70%;
    margin-top: 1em;
    font-size: 14px;
    text-align: center;
  }
}
// 修改遮罩层覆盖
::v-deep .van-overlay {
  position: absolute;
}
::v-deep .van-popup {
  position: absolute;
  width: 100%;
}
.current-span {
  color: #4286f5;
}

.cancel-search-popup {
  margin-top: 48px;
}
</style>
