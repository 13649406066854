<template>
  <div class="van-cell calendars">
    <!-- input-align="right" -->
    <van-field
      readonly
      :border="false"
      :value="datas"
      :name="name"
      :label="label"
      :labelWidth="labelWidth"
      :placeholder="placeholder"
      :required="required"
      :rules="rules"
      @click="handField"
      :right-icon="disabled ? '' : 'arrow'"
      center
    />
    <div class="calendars-mark" v-if="pickerMark">{{ pickerMark }}</div>
    <van-popup
      v-model="showPicker"
      closeable
      round
      :lock-scroll="false"
      close-icon-position="top-left"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <div class="popup-pack">
        <div class="popup-title">
          <span>{{ placeholder }}</span>
        </div>
        <div class="popup-content">
          <div class="popup-content-box">
            <div v-for="(item, index) in options" :key="index" @click="handerOption(item)">
              <span :class="item.value === value ? 'popup-content-label' : ''">
                {{ item.label }}
              </span>
              <van-icon v-if="item.value === value" class="popup-content-icon" name="success" />
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [Array, Object],
      default: null
    },
    value: null,
    options: {
      type: [Array, Object],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    pickerMark: {
      type: String,
      default: null
    },
    labelWidth: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showPicker: false,
      datas: ''
    };
  },
  watch: {
    options: {
      handler: function() {
        if (this.options) {
          this.cascaderColu();
        }
      },
      deep: true,
      immediate: true
    },
    value: {
      handler: function(val, oldVal) {
        let data = this.options;
        data.forEach((Item, index) => {
          if (Item.value == this.value) {
            this.datas = Item.label;
          }
        });
      }
    }
  },
  methods: {
    handField() {
      if (!this.disabled) {
        this.showPicker = true;
      }
    },
    handerOption(data) {
      this.showPicker = false;
      this.datas = data.label;
      this.$emit('input', data.value);
    },
    cascaderColu() {
      let data = this.options;
      data.forEach((Item, index) => {
        if (Item.value == this.value) {
          this.datas = Item.label;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.calendars {
  font-family: PingFangSC-Regular;
  padding: 0;
  flex-direction: column;
  .calendars-mark {
    font-size: 12px;
    color: #969fa9;
    text-align: justify;
    margin: 0 16px 16px 16px;
  }
  .popup-pack {
    height: 100%;
    display: flex;
    flex-direction: column;
    .popup-title {
      height: 54px;
      width: 100%;
      text-align: center;
      line-height: 54px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.85);
    }
    .popup-content {
      display: flex;
      flex: 1;
      overflow: scroll;
      .popup-content-box {
        width: 100%;
        height: 100%;
        .popup-content-label {
          color: #3582ff;
        }
        .popup-content-icon {
          color: #3582ff;
          position: absolute;
          top: 16px;
          right: 16px;
        }
        div {
          height: 48px;
          position: relative;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
          text-align: center;
          line-height: 48px;
        }
      }
    }
  }
}
</style>
